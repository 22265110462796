<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="12" md="5">
        <app-text-search-field
          v-model="searchParams.text"
          @filter="showFilter = !showFilter"
        />
      </v-col>
      <v-col>
        <v-btn class="text-capitalize mr-2" color="primary" @click="select()">
          Pesquisar
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center" v-if="showFilter">
      Filtros
    </v-row>
    <v-row>
      <v-col md="3">
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total das Vendas
              <br />
              <b class="text-h5">
                {{ $format.decimal(salesSum.net_price_cash_total_sum) }}
              </b>
              <br />
              <small>
                {{ $format.decimal(salesSum.profit_sum) }}
              </small>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col md="3">
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Valor de estoque (venda)
              <br />
              <b class="text-h5">
                {{ $format.decimal(productsSum.price_cash_stock_sum) }}
              </b>
            </div>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="sales.data"
          :items-per-page='-1'
          hide-default-footer
        >
          <template v-slot:[`item.net_price_cash_total_sum`]="{ item }">
            {{ $format.decimal(item.net_price_cash_total_sum) }}
          </template>
          <template v-slot:[`item.profit_sum`]="{ item }">
            {{ $format.decimal(item.profit_sum) }}
          </template>
        </v-data-table>
        <v-pagination
          v-model="searchParams.page"
          :length="sales.last_page"
          @input="select($event)"
          :total-visible="9"
        ></v-pagination>
        <!-- <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="-1"
          hide-default-footer
        ></v-data-table> -->

        <!-- 
          cod
          produto 
          quantidade estoque
          preco custo
          total

          somar valor estoque
         -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
import ClassificacaoFiscalSelect from "@/components/product/ui/ClassificacaoFiscalSelect";
import BrandsSearch from "@/components/brands/ui/BrandsSearch.vue";
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CategoriesSearch from "@/components/categories/ui/CategoriesSearch.vue";

export default {
  components: {
    CompanyChip,
    ClassificacaoFiscalSelect,
    BrandsSearch,
    SupplierSearch,
    CategoriesSearch,
  },

  data: () => ({
    headers: [
      {
        text: "Marca",
        align: "start",
        sortable: false,
        value: "brand_name",
      },
      {
        text: "Total Custo",
        value: "net_price_cash_total_sum",
        sortable: false,
        align: "right",
        width: "10%",
      },
      {
        text: "Total Preço Av",
        value: "profit_sum",
        sortable: false,
        align: "right",
        width: "10%",
      },
    ],

    showFilter: false,

    sales: {
      data: [],
    },

    salesSum: {
      price_cash_stock_sum: 0,
      cost_stock_sum: 0,
    },

    searchParams: {
      text: null,
    },
  }),

  created() {},

  methods: {
    select(page = 1) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("sale/sale-by-brand-report", this.searchParams)
        .then((response) => {
          this.$loading.finish();
          this.sales = response.sales;
          this.salesSum = response.salesSum;
        });
    },

    setOrder(order) {
      this.searchParams.order_by = order.order_by;
      this.searchParams.order_direction = order.order_direction;
    },

    handleRelationshipInput(relationship, field) {
      if (relationship) {
        this.searchParams[field] = relationship.id;
      } else {
        this.searchParams[field] = null;
      }
    },

    handleTableUpdate(options) {
      this.options = options;
      this.searchParams.orderBy = options.sortBy[0];
      this.searchParams.orderDirection = options.sortDesc[0] ? "desc" : "asc";
      //this.select(options.page);
    },
  },
};
</script>

<style></style>
